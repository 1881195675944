import React from "react"
import "./styles.scss"

const mouseButtons = {
	left: 0,
	middle: 1,
	right: 2
}

export default class List extends React.Component {
	onClick = (note, ev) => {
		if (
			!ev.shiftKey &&
			ev.button === mouseButtons.left &&
			this.props.onSelect
		)
			this.props.onSelect(note)
		if (
			ev.shiftKey &&
			ev.button === mouseButtons.left &&
			this.props.onRemove
		) {
			this.props.onRemove(note)
		}
	}

	makeDateLabel = date => {
		const dateDifference = new Date().getTime() - date.getTime()
		let dateLabel = date
			.toLocaleDateString()
			.split(".")
			.map(x => x.padStart(2, "0"))
			.join(".")
		if (dateDifference < 1000 * 60 * 60 * 48) dateLabel = "Yesterday"
		if (dateDifference < 1000 * 60 * 60 * 24) dateLabel = "Today"

		const timeLabel = `${date
			.getHours()
			.toString()
			.padStart(2, "0")}:${date
			.getMinutes()
			.toString()
			.padStart(2, "0")}`
		return `${dateLabel}, ${timeLabel}`
	}

	render() {
		const { onSelect, notes, currentNote } = this.props

		return (
			<ul className="list">
				<li onClick={() => onSelect()}>New</li>
				{notes
					.sort((a, b) => b.updated.getTime() - a.updated.getTime())
					.map(note => (
						<li
							className={
								note.id === currentNote.id ? "selected" : ""
							}
							key={note.created}
							onMouseUp={ev => this.onClick(note, ev)}
						>
							<h1>
								{note.title.trim().length > 0
									? note.title
									: "Unnamed Note"}
							</h1>
							<div>{this.makeDateLabel(note.updated)}</div>
						</li>
					))}
			</ul>
		)
	}
}
