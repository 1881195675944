const open = () => JSON.parse(localStorage.getItem("notes")) || []
const close = notes => localStorage.setItem("notes", JSON.stringify(notes))

export class NotesModel {
	constructor(data) {
		this.id = `note-${Date.now()}-${Math.round(Math.random() * Date.now())}`
		this.title = "Unnamed Note"
		this.text = ""
		this.created = new Date()
		this.updated = new Date()

		if (data) Object.keys(data).forEach(key => (this[key] = data[key]))

		if (typeof this.created === "string")
			this.created = new Date(this.created)
		if (typeof this.updated === "string")
			this.updated = new Date(this.updated)
	}

	static getAll() {
		return open().map(note => new NotesModel(note))
	}

	static get(id) {
		const note = open().filter(n => n.id === id)
		return note.length === 1 ? new NotesModel(note) : undefined
	}

	static remove(note) {
		close(open().filter(n => n.id !== note.id))
	}

	static save(note) {
		let hasUpdated = false
		const notes = NotesModel.getAll().map(n => {
			if (n.id === note.id) {
				hasUpdated = true
				return note
			}
			return n
		})
		if (!hasUpdated) notes.push(note)
		close(notes)
	}
}
