import React from "react"
import "./styles.scss"
import { NotesModel } from "../../models"

export default class Editor extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			note: props.note || new NotesModel()
		}
	}

	componentDidMount() {
		this.autofocus()
	}

	componentDidUpdate() {
		if (this.state.note.id !== this.props.note.id) {
			this.autofocus()
			this.setState({ note: this.props.note })
		}
	}

	autofocus = () => document.querySelector("#editor").focus()

	onEdit = ev => {
		const text = ev.target.value
		const title = text
			.split("\n")
			.shift()
			.substr(0, 64)
		const updatedNote = {
			...this.state.note,
			text,
			title,
			updated: new Date()
		}
		NotesModel.save(updatedNote)

		this.setState({
			note: updatedNote
		})

		if (this.props.onChange) this.props.onChange(updatedNote)
	}

	onKeyUp = ev => {
		const isEnter = ev.keyCode === 13
		if (isEnter) {
			let lines = this.state.note.text.split("\n")
			if (lines.length > 1 && lines[lines.length - 2].startsWith(" - "))
				this.setState({
					note: {
						...this.state.note,
						text: this.state.note.text + " - "
					}
				})
		}
	}

	render() {
		const { note } = this.state
		return (
			<textarea
				id="editor"
				onChange={this.onEdit}
				onKeyUp={this.onKeyUp}
				value={note.text}
			/>
		)
	}
}
