import React, { Component } from "react"
import "./App.scss"
import List from "./components/List"
import Editor from "./components/Editor"
import { NotesModel } from "./models"

class App extends Component {
	state = {
		currentNote: new NotesModel(),
		notes: []
	}

	componentDidMount() {
		this.setState({ notes: NotesModel.getAll() })
		document.documentElement.addEventListener("keyup", this.handleHotKeys)
	}

	componentWillUnmount() {
		document.documentElement.removeEventListener(
			"keyup",
			this.handleHotKeys
		)
	}

	handleHotKeys = ev => {
		const numberKey = parseInt(ev.key)
		if (ev.altKey) {
			if (
				numberKey > 0 &&
				numberKey < 10 &&
				this.state.notes.length > numberKey - 1
			) {
				this.setState({
					currentNote: this.state.notes[numberKey - 1]
				})
			}
			if (numberKey === 0)
				this.setState({
					currentNote: new NotesModel()
				})
		}
	}

	openNote = note => {
		this.setState({
			currentNote: note || new NotesModel()
		})
	}

	removeNote = note => {
		NotesModel.remove(note)
		this.setState({
			notes: NotesModel.getAll(),
			currentNote:
				note.id === this.state.currentNote.id
					? new NotesModel()
					: this.state.currentNote
		})
	}

	render() {
		const { currentNote, notes } = this.state

		return (
			<div className="App">
				<List
					onSelect={this.openNote}
					onRemove={this.removeNote}
					notes={notes}
					currentNote={currentNote}
				/>
				<Editor
					note={currentNote}
					onChange={() =>
						this.setState({ notes: NotesModel.getAll() })
					}
				/>
			</div>
		)
	}
}

export default App
